
// src/App.tsx

import theme from "./theme";
import { ThemeProvider } from "./components/ext/styles/StylesExt";
import { MemoryRouter, Routes, Route } from "react-router-dom";
import LoginForm from "./pages/login/LoginForm";
import UserFrame from "./pages/users/User.Frame";
import { AuthProvider } from "./pages/common/AuthContext";

console.log(`The router.tsx loaded all imported files.`);
function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <MemoryRouter initialEntries={["/login"]}>
          <Routes>
            <Route path='/login' element={<LoginForm />} />
            <Route path='/users' element={<UserFrame />} />
          </Routes>
        </MemoryRouter>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
