
// src/pages/users/User.Frame.tsx

import { useState } from "react";
import { styled } from "../../components/ext/styles/StylesExt";
import Typography from "../../components/ext/displays/TypographyExt";

import Box from "../../components/ext/layouts/BoxExt";
import CssBaseline from "../../components/ext/utils/CssBaselineExt";
import UsersHeader from "../../components/wrap/navigation/Header";
import UsersDrawer from "../../components/wrap/navigation/Drawer";

import UserPageFinder from "./UserPage.Finder";
import UserPageAudit from "./UserPage.Audit";
import UserPagePassword from "./UserPage.Password";
import UserPageUsername from "./UserPage.Username";
import UserPageNew from "./UserPage.New";
import UserPageProfile from "./UserPage.Profile";

import { useAuth } from "../common/AuthContext";

const drawerWidth = 150;

const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "center",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: 1,
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function DocumensFrame() {
  const [open, setOpen] = useState(false);
  const { currentPage } = useAuth();
  const { drawerItems } = useAuth();
  const { selectedUsername } = useAuth();
  const { setCurrentPage } = useAuth();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <UsersHeader
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        drawerWidth={drawerWidth}
      >
        <Typography variant='h6' noWrap component='div'>
          User {selectedUsername}
        </Typography>
      </UsersHeader>
      <UsersDrawer
        handleDrawerClose={handleDrawerClose}
        open={open}
        drawerWidth={drawerWidth}
        onPageChange={(pageId) => setCurrentPage(pageId)}
        items={drawerItems}
      />
      <Main>
        <DrawerHeader />
        {currentPage === "Search" && <UserPageFinder />}
        {currentPage === "Audit" && <UserPageAudit />}
        {currentPage === "New" && <UserPageNew />}
        {currentPage === "Username" && <UserPageUsername />}
        {currentPage === "Password" && <UserPagePassword />}
        {currentPage === "Profile" && <UserPageProfile />}
      </Main>
    </Box>
  );
}
