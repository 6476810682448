
// src/pages/users/UserGrid.Finder.tsx

import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Paper from "../../components/wrap/surfaces/Paper";
import TextFieldWrapper from "../../components/wrap/inputs/TextField";
import SearchIcon from "../../components/ext/displays/IconSearchExt";
import GridColDef from "../../components/ext/displays/GridColDefExt";
import DataGrid from "../../components/ext/displays/DataGridExt";

import BadgeIcon from '@mui/icons-material/Badge';

import Box from "../../components/wrap/layouts/Box";
import { useAuth } from "../common/AuthContext";
import { User } from "./User";
import { IconButton } from "@mui/material";

import IconPasswordWrapper from "../../components/ext/displays/IconPasswordExt";
import EditNoteIcon from "../../components/ext/displays/IconEditNoteExt";
import VisibilityIcon from "../../components/ext/displays/IconVisibilityExt";

import loadingGif from '../../images/loading.gif'; 
import ButtonRefresh from "../../components/wrap/inputs/ButtonRefresh";

interface UserGridFinderProps {
  handleOnRefresh: () => void;
}

const UserGridFinder: React.FC<UserGridFinderProps> = ({ handleOnRefresh }) => {
  const [value, setValue] = useState("");
  const [users, setUsers] = useState<User[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [displayLoading, setDisplayLoading] = useState(false);
  const { usersEndpoint } = useAuth();
  const { userEndpoint } = useAuth();
  const { token } = useAuth();
  const { setCurrentPage } = useAuth();
  const { setSelectedUsername } = useAuth();

  const columns: GridColDef[] = [
    {
      field: "usernameOrEmail",
      headerName: "Username",
      width: 300,
      editable: false,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 120,
      editable: false,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 120,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <>
            <IconButton
              onClick={() => {
                setCurrentPage('Username');
                // Acessar o valor do campo 'usernameOrEmail' da linha atual
                const username = params.row.usernameOrEmail;
                // Definir o username no AuthContext
                setSelectedUsername(username);
              }}
              color='primary'
              aria-label='Mudar username'
            >
              <EditNoteIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setCurrentPage('Password');
                // Acessar o valor do campo 'usernameOrEmail' da linha atual
                const username = params.row.usernameOrEmail;
                // Definir o username no AuthContext
                setSelectedUsername(username);
              }}
              color='primary'
              aria-label='Mudar senha'
            >
              <IconPasswordWrapper />
            </IconButton>
          <IconButton
            onClick={() => {
              setCurrentPage('Audit');
              // Acessar o valor do campo 'usernameOrEmail' da linha atual
              const username = params.row.usernameOrEmail;
              // Definir o username no AuthContext
              setSelectedUsername(username);
            }}
            color='primary'
            aria-label='Tentativas'
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setCurrentPage('Profile');
              // Acessar o valor do campo 'usernameOrEmail' da linha atual
              const username = params.row.usernameOrEmail;
              // Definir o username no AuthContext
              setSelectedUsername(username);
            }}
            color='primary'
            aria-label='Profile'
          >
            <BadgeIcon />
          </IconButton>
        </>
      ),
    }
  ];

  const fetchUsers = async () => {
    if (!usersEndpoint) {
      console.error("[UserGridFinder] usersEndpoint está vazio.");
      return;
    }
    console.log(
      `[UserGridFinder] requesting: ${usersEndpoint} with ${token}`,
    );
    try {
      const response = await fetch(usersEndpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Verifica se a resposta é JSON antes de chamar .json()
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        console.error("[UserGridFinder] Resposta não é JSON:", contentType);
        return;
      }

      console.log(`[UserGridFinder] response: ${JSON.stringify(response)}`);
      if (!response.ok) {
        throw new Error(
          "[UserGridFinder] Não foi possível buscar os usuários",
        );
      }
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("[UserGridFinder] Erro ao buscar usuários:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUsers();
      setDisplayLoading(false);
    };
    fetchData();
  }, []);

  const findUser = async () => {
    console.log(`[UserGridFinder] Finding user: ${value}`);
    for (const user of users) {
      if (user.usernameOrEmail === value) {
        console.log(`[UserGridFinder] Found user: ${JSON.stringify(user)}`);
        setUsers([user]); 
        setSelectedUsername(value);
        return ;
      }
    }
    setDisplayLoading(false);
    console.log(`[UserGridFinder] User not found`);
  }

  return (
    <Paper button={<ButtonRefresh onClick={fetchUsers} />}>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "90%",
          padding: "3px",
          margin: "10px",
        }}
      >
        <TextFieldWrapper
          required
          label='Search by typing...'
          placeholder='Search by typing...'
          errorMessage={errorMessage}
          onChange={setValue}
          onBlur={async (v) => await findUser()}
          icon={displayLoading ? (<img src={loadingGif} alt="Loading" style={{ display: "block", height: "15px" }} />): <SearchIcon />}
          value={value}
        />
      </Box>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: "3px",
        }}
      >
        {users.length > 0 ? (
          <DataGrid
            rows={users}
            columns={columns}
            getRowId={(row) => row.usernameOrEmail}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
          />
        ) : (
          <img src={loadingGif} alt="Loading" style={{ display: "block", margin: "0 auto", width: "6%" }} />
        )}
      </Box>
    </Paper>
  );
};

export default UserGridFinder;
