// src/pages/users/UserView.Audit.tsx

import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";

import { useAuth } from "../common/AuthContext";
import Paper from "../../components/wrap/surfaces/Paper";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import Tooltip from '@mui/material/Tooltip';
import LockResetIcon from "@mui/icons-material/LockReset";
import RefreshIcon from '@mui/icons-material/Refresh';

import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';

import { User } from "./User";

interface UserViewProfileProps {
  handleOnRefresh: () => void;
}

const UserViewProfile: React.FC<UserViewProfileProps> = ({
  handleOnRefresh,
}) => {
  const { selectedUsername } = useAuth();
  const { userEndpoint } = useAuth();
  const { token } = useAuth();
  const [user, setUser] = useState<User | null>();

  useEffect(() => {
    // Função para buscar um usuário
    const fetchUser = async () => {
      if (!userEndpoint) {
        console.error("[UserViewProfile] userEndpoint está vazio.");
        return;
      }

      console.log(
        `[UserViewProfile] requesting: ${userEndpoint} with ${token} for ${selectedUsername}`
      );

      try {
        const response = await fetch(`${userEndpoint}/${selectedUsername}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        // Verifica se a resposta é JSON antes de chamar .json()
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          console.error(`[UserViewProfile] Resposta não é JSON:`, contentType);
          return;
        }

        console.log(`[UserViewProfile] response: ${JSON.stringify(response)}`);
        if (!response.ok) {
          throw new Error(
            `[UserViewProfile] Não foi possível buscar o usuário ${selectedUsername}`
          );
        } else {
          const data = await response.json();
          console.log(`[UserViewProfile] response: ${JSON.stringify([data])}`);
          setUser(data);
        }
      } catch (error) {
        console.error(
          `[UserViewProfile] Erro ao buscar o usuário: ${selectedUsername}`,
          error
        );
      }
    };
    // Chama a função fetchUser para carregar dados de um usuário
    if (selectedUsername) {
      fetchUser();
    }
  });

  return (
    <Paper>
        <Box >
          <Typography component="div" variant="h5">
            Profile
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {selectedUsername}
          </Typography>
          <IconButton aria-label="lock">
            {user?.locked ? 
              <Tooltip title="Usuário bloqueado, clique para desbloquear.">
                <LockIcon/>
              </Tooltip> 
            : 
              <Tooltip title="Usuário desbloqueado, clique para bloquear.">
                <LockOpenIcon/>
              </Tooltip> 
            }
          </IconButton>
          <IconButton aria-label="reset">
            {user?.reset ? 
              <Tooltip title="Este usuário deve mudar a senha no próximo acesso.">
                <KeyIcon/>
              </Tooltip> 
            : 
              <Tooltip title="Marcar este usuário deve mudar a senha no próximo acesso.">
                <KeyOffIcon/>
              </Tooltip> 
            }
          </IconButton>
        </Box>
    </Paper>
  );
};

export default UserViewProfile;
