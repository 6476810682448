
// src/pages/users/UserView.Audit.tsx

import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../common/AuthContext";
import Paper from "../../components/wrap/surfaces/Paper";
import IconRemoveModeratorWrapper from "../../components/ext/displays/IconRemoveModeratorExt";
import IconVerifiedUserWrapper from "../../components/ext/displays/IconVerifiedUserExt";
import ButtonRefresh from "../../components/wrap/inputs/ButtonRefresh";
import Avatar from "../../components/ext/displays/AvatarExt";
import ListItemAvatar from "../../components/ext/displays/ListItemAvatarExt";
import ListItemText from "../../components/ext/displays/ListItemTextExt";
import ListItem from "../../components/ext/displays/ListItemExt";
import List from "../../components/ext/displays/ListExt";
import { Attempt } from "./User";

interface UserViewAuditProps {
  handleOnRefresh: () => void;
}

const UserViewAudit: React.FC<UserViewAuditProps> = ({
  handleOnRefresh,
}) => {
  const { selectedUsername } = useAuth();
  const { userAttemptsEndpoint } = useAuth();
  const { token } = useAuth();
  const [attempts, setAttempts] = useState<Attempt[]>([]);
  
  // Função para buscar um usuário
  const fetchUserAttempts = async () => {
    if (!userAttemptsEndpoint) {
      console.error("[UserViewAudit] userEndpoint está vazio.");
      return;
    }

    console.log(
      `[UserViewAudit] requesting: ${userAttemptsEndpoint} with ${token} for ${selectedUsername}`
    );

    try {
      const response = await fetch(`${userAttemptsEndpoint}/${selectedUsername}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Verifica se a resposta é JSON antes de chamar .json()
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        console.error(`[UserViewAudit] Resposta não é JSON:`, contentType);
        return;
      }

      console.log(`[UserViewAudit] response: ${JSON.stringify(response)}`);
      if (!response.ok) {
        throw new Error(
          `[UserViewAudit] Não foi possível buscar o usuário ${selectedUsername}`
        );
      } 
      const data = await response.json();
      console.log(`[UserViewAudit] response: ${JSON.stringify([data])}`);
      setAttempts(data);
    } catch (error) {
      console.error(
        `[UserViewAudit] Erro ao buscar o usuário: ${selectedUsername}`,
        error
      );
    }
  };

  const onRefresh = async () => {
    console.log("[UserViewAudit] onRefresh");
    // Chama a função fetchUser para carregar dados de um usuário
    if (selectedUsername) {
      fetchUserAttempts();
      handleOnRefresh();
    }
  };

  useEffect(() => {
    // Chama a função fetchUser para carregar dados de um usuário
    if (selectedUsername) {
      fetchUserAttempts();
    }
  });

  return (
    <Paper button={<ButtonRefresh onClick={onRefresh} />}>
      <List sx={{ width: "100%", maxWidth: 360 }}>
        {attempts?.map((current, index) => (
          <ListItem key={index}>
            <ListItemAvatar>
              <Avatar>
                {current.token
                  ? 
                  <IconVerifiedUserWrapper sx={{ color: "#D4EDDA" }} />
                  : 
                  <IconRemoveModeratorWrapper sx={{ color: "#FFCCCB" }} />
                }
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={current.username}
              secondary={current.attempt}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default UserViewAudit;
