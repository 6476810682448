
// src/pages/users/UserPage.Profile.tsx

import React, { useState } from "react";
import UserViewProfile from "./UserView.Profile";
import Page from "../../components/wrap/layouts/Page";
import TransitionAlert from "../../components/wrap/feedback/TransitionAlert";

const UserPageProfile: React.FC<any> = () => {
  const [showTransitionAlert, setShowTransitionAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success",
  );
  
  const handleMessageOnRefresh = (
    message: string,
    severity: "success" | "error",
  ) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setShowTransitionAlert(true);
  };

  const handleOnRefresh = () => {
    // TODO: obtain data from server
    handleMessageOnRefresh("Refreshed", "success");
  };

  return (
    <Page>
      {showTransitionAlert && (
        <TransitionAlert message={alertMessage} severity={alertSeverity} />
      )}
      <UserViewProfile
        handleOnRefresh={handleOnRefresh}
      />
    </Page>
  );
};

export default UserPageProfile;
