
// src/pages/login/LoginForm.tsx

import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../common/AuthContext";
import { LoginPaperTheme } from "./LoginPaper.Theme";
import { useTheme } from "../../components/ext/styles/StylesExt";
import IconTextFields from "../../components/ext/displays/IconTextFieldsExt";
import PasswordField from "../../components/wrap/inputs/PasswordField";
import TextField from "../../components/wrap/inputs/TextField";
import Paper from "../../components/ext/surfaces/PaperExt";
import loadingGif from '../../images/loading.gif'; 
import logo from "../../images/logo128x128.png";

console.log(`[LoginPaper]: Loaded imports`);

const LoginForm: React.FC<any> = () => {
  const navigate = useNavigate();
  const currentTheme = useTheme();
  const { token } = useAuth();
  const { login } = useAuth();
  const { loginEndpoint } = useAuth();
  const loginPaperStyles = LoginPaperTheme(currentTheme);

  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [displayLoading, setDisplayLoading] = useState(false);

  console.log(`[LoginForm]: Loaded useState data, navitage and theme`);

  const fetchUserData = async () => {
    console.log(`[LoginForm]: Attempting to log in`);
    try {
      const credentials = { username, password };
      if (validateUsername(username) && validatePassword(password)) {
        const response = await fetch(loginEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(credentials),
        });
        setDisplayLoading(false);
        if (!response.ok) {
          console.error(
            `[LoginForm]: Login request failed with status ${response.status}`,
          );
          throw new Error(`Login request failed with status ${response.status}`);
        }
        const { access_token } = await response.json();
        console.log(`[LoginForm]: Login successful with: ${access_token}`);
        // Atualiza o estado global com o token
        login(access_token);
      }
    } catch (err) {
      console.error(`[LoginForm]: Login error ${err}`);
      setDisplayLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      console.log(`[LoginForm]: Token found, navigating to /users`);
      navigate("/users");
    }
  }, [token, navigate]);

  const validateUsername = (input: string): boolean => {
    if (input) {
      console.log(`[LoginForm]: validateUsername ${input}`);
      if (input.includes("@")) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(input)) {
          setUsernameErrorMessage("Invalid e-mail.");
          return false;
        }
        setUsernameErrorMessage("");
        return true;
      }
      if (input.length < 5) {
        setUsernameErrorMessage("The minimum username length is 5 characters.");
        return false;
      }
      if (input.length > 15) {
        setUsernameErrorMessage(
          "The maximum username length is 15 characters.",
        );
        return false;
      }
      if (/\s/.test(input)) {
        setUsernameErrorMessage("The username cannot contain spaces.");
        return false;
      }
      if (!/^[a-zA-Z0-9_]+$/.test(input)) {
        setUsernameErrorMessage(
          "The username can only contain letters, numbers, and underscores.",
        );
        return false;
      }
    }
    setUsernameErrorMessage("");
    return true;
  };

  const validatePassword = (input: string): boolean => {
    if (input) {
      console.log(`[LoginForm]: validateUsername *****`);
      const passwordRegex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/;
      if (!passwordRegex.test(input)) {
        setPasswordErrorMessage(
          "Password must be 8-16 characters long and include letters, numbers, and special characters.",
        );
        console.error(`[LoginForm]: validateUsername ***** Not OK`);
        return false;
      }
    }
    setPasswordErrorMessage("");
    return true;
  };

  console.log("LoginForm component is rendering");
  return (
    <>
        <Paper sx={loginPaperStyles} elevation={3}>
          <img src={logo} alt='Logo' />
          <TextField
            required
            label='Username or e-mail'
            placeholder='you@domain.com'
            errorMessage={usernameErrorMessage}
            onChange={setUsername}
            onBlur={validateUsername}
            icon={displayLoading ? (<img src={loadingGif} alt="Loading" style={{ display: "block", height: "15px" }} />): <IconTextFields />}
            value={username}
            />
          <PasswordField
            required
            label='Password'
            errorMessage={passwordErrorMessage}
            onChange={(v) => {
              setPassword(v); 
              validatePassword(password);
            }}
            onBlur={(v) => {
              setDisplayLoading(true);
              fetchUserData();
            }}
            value={password}
          />
          
        </Paper>
      </>
      );
}
export default LoginForm;
