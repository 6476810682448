// src/pages/users/UserForm.Password.tsx

import * as React from "react";
import { useState } from "react";
import { useAuth } from "../common/AuthContext";
import Paper from "../../components/wrap/surfaces/Paper";
import PasswordField from "../../components/wrap/inputs/PasswordField";
import ButtonSave from "../../components/wrap/inputs/ButtonSave";
import Box from "../../components/wrap/layouts/Box";

interface UserFormPasswordProps {
  onPasswordChange: (password: string) => void;
  onFormStatusChange: (message: string, severity: "success" | "error") => void;
}

const UserFormPassword: React.FC<UserFormPasswordProps> = ({
  onPasswordChange,
  onFormStatusChange,
}) => {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState("");
  const [repeatPasswordErrorMessage, setRepeatPasswordErrorMessage] =
    useState("");
  const { userPswdEndpoint } = useAuth();
  const { token } = useAuth();
  const { selectedUsername } = useAuth();

  const validateOldPassword = (input: string): boolean => {
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/;
    if (!passwordRegex.test(input)) {
      setOldPasswordErrorMessage(
        "Password must be 8-16 characters long and include letters, numbers, and special characters."
      );
      return false;
    }
    setOldPasswordErrorMessage("");
    return true;
  };

  const validatePassword = (input: string): boolean => {
    const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/;
    if (!passwordRegex.test(input)) {
      setPasswordErrorMessage(
        "Password must be 8-16 characters long and include letters, numbers, and special characters."
      );
      return false;
    }
    setPasswordErrorMessage("");
    return true;
  };

  const validateRepeatPassword = (input: string): boolean => {
    if (password !== input) {
      setRepeatPasswordErrorMessage("Passwords do not match.");
      return false;
    }
    setRepeatPasswordErrorMessage("");
    return true;
  };

    // Função para alterar a senha de um usuário
    const updateUser = async () => {
      if (!userPswdEndpoint) {
        console.error("[UserFormPassword] userEndpoint está vazio.");
        return;
      }
      console.log(
        `[UserFormPassword] requesting: ${userPswdEndpoint} with ${token} for ${selectedUsername}`,
      );
      try {
        const response = await fetch(userPswdEndpoint, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            usernameOrEmail: selectedUsername,
            password: oldPassword,
            newPassword: password
          })
        });
        // Verifica se a resposta é JSON antes de chamar .json()
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          console.error(`[UserFormPassword] Resposta não é JSON:`, contentType);
          return;
        }

        console.log(`[UserFormPassword] response: ${JSON.stringify(response)}`);
        if (!response.ok) {
          throw new Error(
            `[UserFormPassword] Não foi possível buscar o usuário ${selectedUsername}`,
          );
        }
        return true;
      } catch (error) {
        console.error(`[UserFormPassword] Erro ao buscar o usuário: ${selectedUsername}`, error);
      }
      return false;
    };

  const handleOnSave = async () => {
    console.log(`[UserFormPassword] handleOnSave: ${selectedUsername}`);
    const isPasswordValid =
      validatePassword(password) && validateRepeatPassword(repeatPassword);
    if (isPasswordValid) {
      console.log(`[UserFormPassword] handleOnSave: password is valid for ${selectedUsername}`);
      const updatedUserPassword = await updateUser();
      if (updatedUserPassword) {
        console.log(`[UserFormPassword] handleOnSave: password updated for ${selectedUsername}`);
        onFormStatusChange("Formulário submetido com sucesso!", "success");
        onPasswordChange(password);  
      } else {
        console.error(`[UserFormPassword] handleOnSave: server error during changing password for ${selectedUsername}`);
        onFormStatusChange(
          "Erro na submissão do formulário. Verifique os campos.",
          "error"
        );
      }
    }
  };

  return (
    <Paper
      title="Change Password"
      button={<ButtonSave onClick={handleOnSave} />}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "50%",
          padding: "3px",
        }}
      >
        <PasswordField
          required
          label="Latest Password"
          errorMessage={oldPasswordErrorMessage}
          onChange={setOldPassword}
          onBlur={validateOldPassword}
          value={oldPassword}
        />
        <PasswordField
          required
          label="Password"
          errorMessage={passwordErrorMessage}
          onChange={setPassword}
          onBlur={validatePassword}
          value={password}
        />
        <PasswordField
          required
          label="Repeat Password"
          errorMessage={repeatPasswordErrorMessage}
          onChange={setRepeatPassword}
          onBlur={validateRepeatPassword}
          value={repeatPassword}
        />
      </Box>
    </Paper>
  );
};

export default UserFormPassword;
