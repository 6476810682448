
// src/pages/users/UserForm.New.tsx

import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Paper from "../../components/wrap/surfaces/Paper";
import TextField from "../../components/wrap/inputs/TextField";
import PasswordField from "../../components/wrap/inputs/PasswordField";
import IconTextFields from "../../components/ext/displays/IconTextFieldsExt";
import ButtonSave from "../../components/wrap/inputs/ButtonSave";
import Box from "../../components/wrap/layouts/Box";
import { useAuth } from "../common/AuthContext";

interface UserFormNewProps {
  onFormStatusChange: (message: string, severity: "success" | "error") => void;
}

const UserFormNew: React.FC<UserFormNewProps> = ({ onFormStatusChange }) => {
  const { token } = useAuth();
  const { usersEndpoint } = useAuth();
  const { selectedUsername } = useAuth();
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [repeatUsernameErrorMessage, setRepeatUsernameErrorMessage] =
    useState("");
  const [repeatPasswordErrorMessage, setRepeatPasswordErrorMessage] =
    useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [repeatUsername, setRepeatUsername] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  useEffect (() => {
    setUsername("");
    setPassword("");
    setRepeatUsername("");
    setRepeatPassword("");
  }, [selectedUsername]);

  const validateUsername = (input: string): boolean => {
    setUsernameErrorMessage("");
    if (input && input !== null && input !== "" && input.length > 0) {
      if (input.includes("@")) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(input)) {
          setUsernameErrorMessage("Invalid e-mail.");
          return false;
        }
        return true;
      }
      if (input.length < 5) {
        setUsernameErrorMessage("The minimum username length is 5 characters.");
        return false;
      }
      if (input.length > 15) {
        setUsernameErrorMessage(
          "The maximum username length is 15 characters.",
        );
        return false;
      }
      if (/\s/.test(input)) {
        setUsernameErrorMessage("The username cannot contain spaces.");
        return false;
      }
      if (!/^[a-zA-Z0-9_]+$/.test(input)) {
        setUsernameErrorMessage(
          "The username can only contain letters, numbers, and underscores.",
        );
        return false;
      }
    }
    setUsernameErrorMessage("");
    return true;
  };

  const validateRepeatUsername = (input: string): boolean => {
    if (username !== input) {
      setRepeatUsernameErrorMessage("Usernames do not match.");
      return false;
    }
    setRepeatUsernameErrorMessage("");
    return true;
  };

  const validatePassword = (input: string): boolean => {
    setPasswordErrorMessage("");
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/;
    if (!passwordRegex.test(input)) {
      setPasswordErrorMessage(
        "Password must be 8-16 characters long and include letters, numbers, and special characters.",
      );
      return false;
    }
    return true;
  };

  const validateRepeatPassword = (input: string): boolean => {
    if (password !== input) {
      setRepeatPasswordErrorMessage("Passwords do not match.");
      return false;
    }
    setRepeatPasswordErrorMessage("");
    return true;
  };

  const registerUser = async () => {
    const url = usersEndpoint;
    console.log(`URL do Endpoint: ${url}`);

    console.log(`Token de Autorização: ${token}`);

    const requestBody = JSON.stringify({
      usernameOrEmail: username,
      password: password,
    });
    console.log(
      "Dados do Usuário sendo preparados para envio:",
      JSON.stringify(requestBody),
    );

    console.log("Iniciando solicitação POST para registro de usuário...");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: requestBody,
    });

    console.log("Solicitação POST concluída.", JSON.stringify(response));

    if (!response.ok) {
      const message = `Um erro ocorreu: ${response.status}`;
      console.error(message);
      throw new Error(message);
    }

    const data = await response.json();
    console.log("Resposta recebida do servidor:", data);
    return data;
  };

  const handleOnSave = async () => {
    if (
      validateUsername(username) &&
      validatePassword(password) &&
      validateRepeatUsername(repeatUsername) &&
      validateRepeatPassword(repeatPassword)
    ) {
      onFormStatusChange("Formulário submetido com sucesso!", "success");
      try {
        const data = await registerUser();
        onFormStatusChange("Usuário registrado com sucesso!", "success");
        console.log("Usuário registrado:", data);
      } catch (error) {
        console.error("Erro ao registrar usuário:", error);
        onFormStatusChange("Erro ao registrar usuário.", "error");
      }
    } else {
      onFormStatusChange(
        "Erro na submissão do formulário. Verifique os campos username && password && repeat username && repeat password.",
        "error",
      );
    }
  };

  return (
    <Paper title='New User' button={<ButtonSave onClick={handleOnSave} />}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "50%",
          padding: "3px",
        }}
      >
        <TextField
          required
          label='Username or e-mail'
          placeholder='you@domain.com'
          errorMessage={usernameErrorMessage}
          onChange={setUsername}
          icon={<IconTextFields />}
          value={username}
        />
        <TextField
          required
          label='Repeat Username or e-mail'
          placeholder='Confirm your e-mail'
          errorMessage={repeatUsernameErrorMessage}
          onChange={setRepeatUsername}
          icon={<IconTextFields />}
          value={repeatUsername}
        />
        <PasswordField
          required
          label='Password'
          errorMessage={passwordErrorMessage}
          onChange={setPassword}
          onBlur={validatePassword}
          value={password}
        />
        <PasswordField
          required
          label='Repeat Password'
          errorMessage={repeatPasswordErrorMessage}
          onChange={setRepeatPassword}
          onBlur={validateRepeatPassword}
          value={repeatPassword}
        />
      </Box>
    </Paper>
  );
};

export default UserFormNew;
